
import React, { Component } from "react";

class Support extends Component {
    render() {
        return (
            <div className="support-wrapper">
                <section className="section support-title">
                    <div className="inner inner-full">

                        <h2>Support</h2>
                        <h3>INFORMATION OVERLOAD?</h3>
                        <p>Don’t worry – we’re here to help, just ask your friendly neighbourhood Metaverse Squad.</p>
                        <p>You can chat with us for help, via our <a className="light-link" href="https://teams.microsoft.com/l/channel/19%3avxM4Az5rKqX1TUVjoLY84CYinrk1B57EfbEiEqsuP3c1%40thread.tacv2/General?groupId=9c862fe2-2ea2-494d-b001-dddfc277d392&tenantId=02ebbf09-937d-429d-ab18-aa9e3229b603">dedicated Teams channel</a>.</p>
                        <p>Or if you prefer, send us an email at <a href="mailto:metaverse@superunion.com" className="light-link">metaverse@superunion.com</a>.</p>
                        <p>We’ll also have a team in the metaverse, ready to welcome you when you arrive.</p>

                    </div>
                </section>
            </div>
        );
    }
}

export default Support
