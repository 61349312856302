
import { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import './scss/app.scss'
import Portal from './components/Portal';
import UserGuide from './components/UserGuide';
import Menu from './components/Menu';
import Maps from './components/Maps';
import Support from './components/Support';
import AudioPlayer from './components/AudioPlayer';
import CookieModal from './components/CookieModal';

import { portal_urls } from './PORTAL_URL_LIST';

class App extends Component {

    componentWillMount() {
        let portal_url = portal_urls[Math.floor(Math.random() * portal_urls.length)];
        let touch_enabled = 'ontouchend' in window;

        this.setState({
            menu_showing: false,
            muted: true,
            hovering: false,
            clicked: Math.random(),
            loop_playing: false,
            modal_showing: true,
            interacted: false,
            portal_url,
            touch_enabled
        });
    }

    componentDidUpdate(props, state) {
        if (state.clicked !== this.state.clicked) setTimeout( () => {
            window.location.href = this.state.portal_url;
        }, 500);
    }

    toggle_menu() {
        this.setState({ menu_showing: !this.state.menu_showing });
    }

    toggle_mute(e) {
        e.preventDefault();
        this.setState({ muted: !this.state.muted });
    }

    update(obj) {
        this.setState(obj);
    }

    render() {

        const PortalComponent = (
            <Portal
                hovering={this.state.hovering}
                clicked={this.state.clicked}
                interacted={this.state.interacted}
                touch_enabled={this.state.touch_enabled}
                portal_url={this.state.portal_url}
                update={this.update.bind(this)}
                />
        );

        return (
            <div className="app-container">
                <Router>
                    <header>
                        <button className="menu-button" onClick={() => this.toggle_menu()}>Wait, what?</button>
                        <button className={`sound-toggle ${this.state.muted ? 'muted' : ''}`} onClick={e => this.toggle_mute(e)} />
                    </header>
                    <Menu showing={this.state.menu_showing} toggle_menu={this.toggle_menu.bind(this)} />
                    <Routes>
                        <Route path="/user-guide" element={<UserGuide />} />
                        <Route path="/maps" element={<Maps />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/" element={PortalComponent} />
                   </Routes>
               </Router>
               <AudioPlayer muted={this.state.muted} hovering={this.state.hovering} clicked={this.state.clicked} loop_playing={this.state.loop_playing} update={this.update.bind(this)} />
               <CookieModal muted={this.state.muted} update={this.update.bind(this)} modal_showing={this.state.modal_showing} />
           </div>
        );
    }
}

export default App;
