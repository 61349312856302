
import React, { Component } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
    render() {

        let { showing, toggle_menu } = this.props;

        return (
            <div className={`menu-wrapper ${ showing ? 'showing' : '' }`}>
                <button className="menu--close-btn" onClick={toggle_menu}>Close</button>
                <ul className="menu--items">
                    <li><Link to="/" onClick={toggle_menu}>Home</Link></li>
                    <li><Link to="/user-guide" onClick={toggle_menu}>User Guide</Link></li>
                    <li><Link to="/maps" onClick={toggle_menu}>Maps</Link></li>
                    <li><Link to="/support" onClick={toggle_menu}>Support</Link></li>
                    <li><a href="https://hubs.superunion.co/">Portals</a></li>
                </ul>
            </div>
        );
    }
}

export default Menu;
