
import React, { Component } from "react";

import map_1 from '../assets/images/map-1.jpg';
import map_2 from '../assets/images/map-2.jpg';
import map_3 from '../assets/images/map-3.jpg';
import map_4 from '../assets/images/map-4.jpg';

import icon_smile from '../assets/images/smile.svg';

class Maps extends Component {
    render() {
        return (
            <div className="maps-wrapper">
                <section className="section maps-title">
                    <div className="inner inner-full">

                        <h2>Maps</h2>

                        <p>Want a sneak peak at what lies before you? These maps can help show you the way... but don't worry, there will be lots of help to guide you!</p>

                        <p>One final thing before you go, there are some secret rooms - look closely and you might just find them. <img className="icon-smile" src={icon_smile} /></p>

                        <ul className="map-list">
                            <li><img src={map_1} alt="Lobby of learning" /></li>
                            <li><img src={map_2} alt="Room of roots" /></li>
                            <li><img src={map_3} alt="Forest of the unseen" /></li>
                            <li><img src={map_4} alt="Canopy of the infinite" /></li>
                        </ul>

                    </div>
                </section>
            </div>
        );
    }
}

export default Maps
