
import React, { Component } from "react";
import logo from '../assets/images/superunion-logo-dark.svg';

class CookieModal extends Component {

    render() {
        let { modal_showing } = this.props;

        return (
            <>
                <div className={`modal-bg ${modal_showing ? 'showing' : ''}`} onClick={() => this.props.update({ muted: false, modal_showing: false })} />
                <div className={`modal-wrapper ${modal_showing ? 'showing' : ''}`}>
                    <div className="modal-content">
                        <img src={logo} alt="SUPERUNION." />
                        <p>This site uses cookies and by using the site you are consenting to this. Find out why we use cookies and how to manage your settings. <a href="https://www.superunion.com/services/privacy-policies/" target="_blank" rel="noreferrer">More about cookies</a>.</p>
                        <button onClick={() => this.props.update({ muted: false, modal_showing: false })}>Accept cookies</button>
                    </div>
                </div>
            </>
        )
    }
}

export default CookieModal;
