
import { Component } from "react";

import image007 from '../assets/images/image-007.jpg';
import image009 from '../assets/images/image-009.jpg';
import image014 from '../assets/images/image-014.jpg';
import image016 from '../assets/images/image-016.jpg';
import image021 from '../assets/images/image-021.jpg';

import controls_wsad from '../assets/images/controls-wsad.png';
import controls_pointer from '../assets/images/controls-pointer.png';
import controls_shift_w from '../assets/images/controls-shift-w.png';
import controls_g from '../assets/images/controls-g.png';

import controls_take_a_picture_4 from '../assets/images/controls-take-a-picture-4.png';
import controls_take_a_picture_3 from '../assets/images/controls-take-a-picture-3.png';
import controls_take_a_picture_2 from '../assets/images/controls-take-a-picture-2.png';
import controls_take_a_picture_1 from '../assets/images/controls-take-a-picture-1.png';

import controls_drawing_4 from '../assets/images/controls-drawing-4.png';
import controls_drawing_3 from '../assets/images/controls-drawing-3.png';
import controls_drawing_2 from '../assets/images/controls-drawing-2.png';
import controls_drawing_1 from '../assets/images/controls-drawing-1.png';

class UserGuide extends Component {

    render(props) {
        return (
            <div className="user-guide-wrapper">
                <section className="section user-guide-title">
                    <div className="inner inner-full">
                        <h2><span>Revolutionary Road</span> <span>User Guide</span></h2>
                    </div>
                </section>

                <section className="section get-started">
                    <div className="inner inner-full">
                        <div className="inner inner-half">
                            <h2>Get Started</h2>
                            <h3>A FEW BEGINNER TIPS:</h3>
                            <ol>
                                <li>Use <a href="https://www.google.co.uk/chrome/" target="_blank" rel="noreferrer">Google Chrome</a> or <a href="https://www.mozilla.org/en-GB/firefox/new/" target="_blank" rel="noreferrer">Mozilla Firefox</a> as your browser (avoid Safari if possible!)</li>
                                <li>Close any Teams or video calls before going in.</li>
                                <li>Headphones and a mouse are recommended</li>
                                <li>There will be a loading screen at first - just be patient!</li>
                                <li>Allow Mozilla Hubs to access your microphone. There should be a pop-up window to allow it. Alternatively, click the microphone icon in your browser’s search bar to allow it. If it still doesn’t work, reach out on the global Teams Channel.</li>
                                <li>We recommend you sign in to access more features, like taking selfies or using a custom avatar. Simply enter your email and verify through the link</li>
                            </ol>
                        </div>
                        <div className="inner inner-half">
                            <div className="guide-picture">
                                <img src={image007} alt="SUPERUNION." />
                                <span className="guide-picture-label">Allow microphone access via pop-up</span>
                            </div>
                            <div className="guide-picture">
                                <img src={image009} alt="SUPERUNION." />
                                <span className="guide-picture-label">Or allow it via search bar icon</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section avatars">
                    <div className="inner inner-full">
                        <div className="inner inner-half">
                            <h2>Avatars</h2>

                            <p>You can use a pre-made avatar by following the steps below. Alternatively, you can create a custom avatar following the steps on the next page.</p>
                            <h3>PICKING A PRE-MADE AVATAR:</h3>
                            <ol>
                                <li>Once you’re in Mozilla Hubs, click the <strong>More</strong> menu in the bottom-right</li>
                                <li>Click <strong>Change Name & Avatar</strong> then <strong>Change Avatar</strong></li>
                                <li>In the <strong>Featured</strong> tab you can select from a list of premade avatars</li>
                                <li>Pick your avatar, then click <strong>Accept</strong></li>
                            </ol>
                        </div>
                        <div className="inner inner-half">
                            <div className="guide-picture">
                                <img src={image014} alt="SUPERUNION." />
                                <span className="guide-picture-label">Change name &amp; avatar</span>
                            </div>
                            <div className="guide-picture">
                                <img src={image016} alt="SUPERUNION." />
                                <span className="guide-picture-label">Featured tab</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section make-your-own-avatar">
                    <div className="inner inner-full">
                        <div className="inner inner-half">
                            <h2>Make your own avatar</h2>

                            <h3>CUSTOM AVATARS:</h3>
                            <p>Create your own avatar at:<br/><a className="light-link" href="https://avatars.superunion.co/" target="_blank" rel="noreferrer">https://avatars.superunion.co/</a></p>
                            <p>Click <strong>Export Avatar</strong> to download the GLB file</p>
                            <h3>ONCE YOU’RE IN MOZILLA HUBS:</h3>
                            <ol>
                                <li>Click the <strong>More</strong> menu in the bottom-right</li>
                                <li>Click <strong>Change Name & Avatar</strong> then <strong>Change Avatar</strong></li>
                                <li>Go to the <strong>My Avatars</strong> tab, then <strong>Create Avatar</strong> (you’ll need to sign in to do this)</li>
                                <li>Click <strong>Custom GLB</strong> and upload your downloaded GLB file</li>
                                <li>Click <strong>Save</strong>, pick your avatar, and click <strong>Accept</strong></li>
                            </ol>
                        </div>
                        <div className="inner inner-half">
                            <div className="guide-picture">
                                <img src={image014} alt="SUPERUNION." />
                                <span className="guide-picture-label">Change name &amp; avatar</span>
                            </div>
                            <div className="guide-picture">
                                <img src={image021} alt="SUPERUNION." />
                                <span className="guide-picture-label">Custom GLB button</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section controls-1">
                    <div className="inner inner-full">
                        <h2>Controls</h2>
                        <div className="inner inner-half">
                            <h3>Basic movement</h3>
                            <img src={controls_wsad} className="control-picture" alt="SUPERUNION." />
                            <ul className="controls-wsad">
                                <li><span>W</span>To go forward</li>
                                <li><span>S</span>To go backward</li>
                                <li><span>A</span>To go left</li>
                                <li><span>D</span>To go right</li>
                            </ul>
                        </div>
                        <div className="inner inner-half">
                            <h3>Looking around</h3>
                            <img src={controls_pointer} className="control-picture" alt="SUPERUNION." />
                            <ul className="controls-list">
                                <li>Look around</li>
                                <li>Hold <strong>mouse button</strong> + drag</li>
                                <li>Or press <strong>Q / E</strong> to turn</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="section controls-2">
                    <div className="inner inner-full">
                        <h2>Controls</h2>
                        <div className="inner inner-half">
                            <h3>Running</h3>
                            <img src={controls_shift_w} className="control-picture" alt="SUPERUNION." />
                            <ul className="controls-list">
                                <li>Hold <strong>shift</strong> to go faster</li>
                            </ul>
                        </div>
                        <div className="inner inner-half">
                            <h3>Flying</h3>
                            <img src={controls_g} className="control-picture" alt="SUPERUNION." />
                            <ul className="controls-list">
                                <li>Press <strong>G</strong> to fly<br/>(and again to land)</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="section take-a-picture">
                    <div className="inner inner-full">
                        <h2>Take a picture</h2>
                        <ul className="controls-icon-list">
                            <li>
                                <img src={controls_take_a_picture_1} alt="SUPERUNION." />
                                <span>PLACE THE CAMERA FROM THE MENU, THEN LEFT-CLICK AND DRAG TO POSITION THE CAMERA</span>
                            </li>
                            <li>
                                <img src={controls_take_a_picture_2} alt="SUPERUNION." />
                                <span>SNAP A PICTURE OR RECORD A VIDEO (CHOOSE A DURATION!)</span>
                            </li>
                            <li>
                                <img src={controls_take_a_picture_3} alt="SUPERUNION." />
                                <span>WHEN YOUR PICTURE OR VIDEO APPEARS, HOLD SPACEBAR TO OPEN THE MENU FOR MORE OPTIONS</span>
                            </li>
                            <li>
                                <img src={controls_take_a_picture_4} alt="SUPERUNION." />
                                <span>HOLD SPACEBAR ON THE CAMERA AND CLOSE IT WHEN YOU’RE DONE</span>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="section drawing">
                    <div className="inner inner-full">
                        <h2>Drawing</h2>
                        <ul className="controls-icon-list">
                            <li>
                                <img src={controls_drawing_1} alt="SUPERUNION." />
                                <span>FROM THE PLACE MENU, SELECT PEN</span>
                            </li>
                            <li>
                                <img src={controls_drawing_2} alt="SUPERUNION." />
                                <span>LEFT-CLICK TO DRAW ON THE CLOSEST SURFACE</span>
                            </li>
                            <li>
                                <img src={controls_drawing_3} alt="SUPERUNION." />
                                <span>SHIFT + Q/E TO CHANGE COLOUR</span>
                            </li>
                            <li>
                                <img src={controls_drawing_4} alt="SUPERUNION." />
                                <span>SHIFT + MOUSE WHEEL TO INCREASE/DECREASE SIZE</span>
                            </li>
                            <li>
                                <img src={controls_take_a_picture_4} alt="SUPERUNION." />
                                <span>HOLD SPACEBAR ON YOUR ARTWORK TO UNDO OR DELETE</span>
                            </li>
                        </ul>
                    </div>
                </section>

            </div>
        );
    }
}

export default UserGuide;
