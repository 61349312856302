
import React, { Component } from "react";

// import op6 from '../assets/audio/op6.mp3';
// import op5 from '../assets/audio/op5.mp3';
// import op4 from '../assets/audio/op4.mp3';
// import flicker from '../assets/audio/flicker.mp3';

import loop from '../assets/audio/loop.mp3';
import hover from '../assets/audio/hover.mp3';
import click from '../assets/audio/click.mp3';

class AudioPlayer extends Component {

    componentDidUpdate(props) {

        let {
            muted,
            loop_playing,
            hovering,
            clicked
        } = this.props;

        /**
         * Action: Un-muted
         */
        if (props.muted && !muted) {
            if (!loop_playing) {
                this.loop.play();
                this.props.update({ loop_playing: true });
            }
        }

        /**
         * Action: muted
         */
        if (!props.muted && muted) {
            if (loop_playing) {
                this.loop.pause();
                this.props.update({ loop_playing: false });
            }
        }

        /**
         * One-off sound triggers.
         */
        if (!muted) {
            if (!props.hovering && hovering) {
                this.hover.play();
            }
            if (props.clicked !== clicked) {
                this.click.play();
            }
        }
    }

    render() {
        return (
            <div className="audio-player-wrapper" style={{ display: 'none' }}>

                {/*<audio src={op6} ref={r => this.op6 = r} preload="true" />
                <audio src={op5} ref={r => this.op5 = r} preload="true" />
                <audio src={op4} ref={r => this.op4 = r} preload="true" />
                <audio src={flicker} ref={r => this.flicker = r} preload="true" />*/}

                <audio src={loop} ref={r => this.loop = r} preload="true" loop />
                <audio src={hover} ref={r => this.hover = r} preload="true" />
                <audio src={click} ref={r => this.click = r} preload="true" />
            </div>
        )
    }
}

export default AudioPlayer;
