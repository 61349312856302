import * as THREE from "three";
import matcap1 from '../assets/matcaps/matcap1.png';
import matcap2 from '../assets/matcaps/matcap2-optimised.png';


export default class Lines {
    constructor() {
    
    this.myshapes = new THREE.Group();
    this.myuniforms = [];
    let numSides=4;
    let subdivision=100;

    var myCylinderGeometry = new THREE.CylinderBufferGeometry( 
        1, 1, 1, 
        numSides , subdivision);

    const tmpVec = new THREE.Vector2();
    // set values of attributes
    myCylinderGeometry.rotateZ(Math.PI / 2);

    var values = new Float32Array(myCylinderGeometry.attributes.position.array.length/3);
    var xpositions = new Float32Array(myCylinderGeometry.attributes.position.array.length/3);
    var indices = new Float32Array(myCylinderGeometry.attributes.position.array.length/3);
    var indices2 = new Float32Array(myCylinderGeometry.attributes.position.array.length/3);
    let index =0;

    for ( var i = 0; i < myCylinderGeometry.attributes.position.array.length; i+=3 ) 
    {
        var vecx = myCylinderGeometry.attributes.position.array[i];
        var vecy = myCylinderGeometry.attributes.position.array[i+1];
        var vecz = myCylinderGeometry.attributes.position.array[i+2];
        
        tmpVec.set(vecy, vecz).normalize();

        // the radial angle around the tube
        const angle = Math.atan2(tmpVec.y, tmpVec.x);
        values[index] = (angle);
        xpositions[index] = (vecx)
        index++;
        indices[index] = index
        indices2[index] = index + 1000020.2;
    }

     let matcap_texture = new THREE.TextureLoader().load(matcap1);
     let matcap_texture4 = new THREE.TextureLoader().load(matcap2);
  
    let mytextures = [];
    mytextures.push(matcap_texture)
    mytextures.push(matcap_texture4)
  
    for(let i = 1; i < 15; i++){

        let tmp_type = 0;
        let tmp_thickness = 0.5 + Math.random()*2;

        let  mycustomUniforms = 
        {
            mixAmount:   { type: "f", value: 0.0 },
            thickness: { type: 'f', value:  0.1 + tmp_thickness },
            time: { type: 'f', value: 0 },
            radialSegments: { type: 'f', value: numSides },
            lengthSegments: { type: 'f', value: subdivision },
            type:  { type: "f", value: 0 },
            radius:  { type: "f", value: 8 + Math.random()*15 },
            noiseScale:  { type: "f", value: 10 },
            noiseStrength:  { type: "f", value: 20 },
            tCube:      { type: "t", value: null }, //  textureCube }
            curlPosition: { value: new THREE.Vector3(i/2,0,0) },
            tMatCap: { type: 't', value: mytextures[Math.floor(Math.random()*mytextures.length)]},
            mycenter :   {  value: new THREE.Vector3(Math.random()*20-10,Math.random()*20-10,Math.random()*20-10) }, //  textureCube 
            lightPosition :{  value: new THREE.Vector3(0,0,0) },
    };

    var customMaterial = new THREE.ShaderMaterial( 
    {
        uniforms: mycustomUniforms,
        vertexShader:  
        `

uniform vec3 lightPosition;
uniform float noiseScale;
uniform float radius;
uniform float type;
uniform float noiseStrength;

uniform float mixAmount;
attribute float angle;
attribute float xposition;
attribute float indices;
uniform vec3 curlPosition;
varying vec2 vN;
uniform vec3 viewVector;
varying float intensity;
varying vec2 vUv;
varying float increment;

// pass a few things along to the vertex shader
varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 uPosition;

// custom uniforms to build up our tubes
uniform vec3 mycenter;
uniform float thickness;
uniform float lengthSegments;
uniform float animateRadius;
uniform float animateStrength;
uniform float index;
uniform float radialSegments;

///////////////////////////////////////////////////////////////////////////////////////////////////
vec3 mod289(vec3 x) {
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 mod289(vec4 x) {
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x) {
     return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r)
{
  return 1.79284291400159 - 0.85373472095314 * r;
}

vec3 fade(vec3 t) {
  return t*t*t*(t*(t*6.0-15.0)+10.0);
}

float pnoise(vec3 P, vec3 rep)
{
  vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
  vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
  return 2.2 * n_xyz;
}

float snoise(vec3 v)
  {
  const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
  const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

// First corner
  vec3 i  = floor(v + dot(v, C.yyy) );
  vec3 x0 =   v - i + dot(i, C.xxx) ;

// Other corners
  vec3 g = step(x0.yzx, x0.xyz);
  vec3 l = 1.0 - g;
  vec3 i1 = min( g.xyz, l.zxy );
  vec3 i2 = max( g.xyz, l.zxy );

  //   x0 = x0 - 0.0 + 0.0 * C.xxx;
  //   x1 = x0 - i1  + 1.0 * C.xxx;
  //   x2 = x0 - i2  + 2.0 * C.xxx;
  //   x3 = x0 - 1.0 + 3.0 * C.xxx;
  vec3 x1 = x0 - i1 + C.xxx;
  vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
  vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y

// Permutations
  i = mod289(i);
  vec4 p = permute( permute( permute(
             i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
           + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
           + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

// Gradients: 7x7 points over a square, mapped onto an octahedron.
// The ring size 17*17 = 289 is close to a multiple of 49 (49*6 = 294)
  float n_ = 0.142857142857; // 1.0/7.0
  vec3  ns = n_ * D.wyz - D.xzx;

  vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

  vec4 x_ = floor(j * ns.z);
  vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

  vec4 x = x_ *ns.x + ns.yyyy;
  vec4 y = y_ *ns.x + ns.yyyy;
  vec4 h = 1.0 - abs(x) - abs(y);

  vec4 b0 = vec4( x.xy, y.xy );
  vec4 b1 = vec4( x.zw, y.zw );

  //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
  //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
  vec4 s0 = floor(b0)*2.0 + 1.0;
  vec4 s1 = floor(b1)*2.0 + 1.0;
  vec4 sh = -step(h, vec4(0.0));

  vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
  vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

  vec3 p0 = vec3(a0.xy,h.x);
  vec3 p1 = vec3(a0.zw,h.y);
  vec3 p2 = vec3(a1.xy,h.z);
  vec3 p3 = vec3(a1.zw,h.w);

//Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;

// Mix final noise value
  vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
  m = m * m;
  return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                dot(p2,x2), dot(p3,x3) ) );
  }


// Classic Perlin noise
float cnoise(vec3 P)
{
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod289(Pi0);
  Pi1 = mod289(Pi1);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 * (1.0 / 7.0);
  vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 * (1.0 / 7.0);
  vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
  return 2.2 * n_xyz;
}
////////////////////////////////////////////////////////////////////////////////////////////////////
vec3 computeCurl(float x,float y,float z){
  float eps = 0.0001;

  vec3 curl = vec3(0.);

  //Find rate of change in YZ plane
  float n1 = cnoise(vec3(x, y + eps, z)); 
  float n2 = cnoise(vec3(x, y - eps, z)); 
  //Average to find approximate derivative
  float a = (n1 - n2)/(2. * eps);
  n1 = cnoise(vec3(x, y, z + eps)); 
  n2 = cnoise(vec3(x, y, z - eps)); 
  //Average to find approximate derivative
  float b = (n1 - n2)/(2. * eps);
  curl.x = a - b;

  //Find rate of change in XZ plane
  n1 = cnoise(vec3(x, y, z + eps)); 
  n2 = cnoise(vec3(x, y, z - eps)); 
  a = (n1 - n2)/(2. * eps);
  n1 = cnoise(vec3(x + eps, y, z)); 
  n2 = cnoise(vec3(x - eps, y, z)); 
  b = (n1 - n2)/(2. * eps);
  curl.y = a - b;

  //Find rate of change in XY plane
  n1 = cnoise(vec3(x + eps, y, z)); 
  n2 = cnoise(vec3(x - eps, y, z)); 
  a = (n1 - n2)/(2. * eps);
  n1 = cnoise(vec3(x, y + eps, z)); 
  n2 = cnoise(vec3(x, y - eps, z)); 
  b = (n1 - n2)/(2. * eps);
  curl.z = a - b;

  return curl;
}

///////////////////////////////////////////////////////////////////////////77

vec3 snoiseVec3( vec3 x ){

  float s  = snoise(vec3( x ));
  float s1 = snoise(vec3( x.y - 19.1 , x.z + 33.4 , x.x + 47.2 ));
  float s2 = snoise(vec3( x.z + 74.2 , x.x - 124.5 , x.y + 99.4 ));
  vec3 c = vec3( s , s1 , s2 );
  return c;

}


vec3 curlNoise( vec3 p ){
  
  const float e = .1;
  vec3 dx = vec3( e   , 0.0 , 0.0 );
  vec3 dy = vec3( 0.0 , e   , 0.0 );
  vec3 dz = vec3( 0.0 , 0.0 , e   );

  vec3 p_x0 = snoiseVec3( p - dx );
  vec3 p_x1 = snoiseVec3( p + dx );
  vec3 p_y0 = snoiseVec3( p - dy );
  vec3 p_y1 = snoiseVec3( p + dy );
  vec3 p_z0 = snoiseVec3( p - dz );
  vec3 p_z1 = snoiseVec3( p + dz );

  float x = p_y1.z - p_y0.z - p_z1.y + p_z0.y;
  float y = p_z1.x - p_z0.x - p_x1.z + p_x0.z;
  float z = p_x1.y - p_x0.y - p_y1.x + p_y0.x;

  const float divisor = 1.0 / ( 2.0 * e );
  return normalize( vec3( x , y , z ) * divisor );

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

vec3 mysample (float t) {
  float x = sin(t * 15.0 / 2.0);
  //vec3 tmp=curlNoise(vec3(t* xposition+curlPosition.x  + mixAmount/2.));
  vec3 tmp2=curlNoise(vec3(t* +xposition  + mixAmount));
  //x= tmp.x ;
  float y = cos(t * 5.0 / curlPosition.z); ;

vec3 curlPosition2 = vec3(curlPosition);

float displacement =  10.5 * abs(pnoise( vec3(indices)/0.5 + mixAmount, vec3( 10.0 ) ));

 float yvalue = (normal.y * displacement);
   float xvalue = (normal.x * 2. );
   float zvalue = (normal.z * 2. );

 float factor= 0.8; //noiseScale*10. ;//
 float factor2= 200.;//noiseStrength;//1.;


  //vec3 tmp = curlNoise( curlPosition2+t/noiseScale  + mixAmount/noiseStrength);//);
  vec3 tmp = curlNoise( curlPosition2+t/noiseScale  + mixAmount/50.);//);


  //float distortion = pnoise((tmp), vec3(1.0) * 510.) * 1.;
  float distortion = cnoise((tmp+t/noiseScale)) * 1.;

  vec3 pos = vec3(3.5 * distortion );
 
  //vec3 result = mycenter + tmp * radius + pos ;
  vec3 result = vec3(0.) + tmp * radius + pos ;

  return  result;

}



void createTube (float t, vec2 volume, out vec3 offset, out vec3 normal) {
  // find next sample along curve
  float nextT = t + (1.0 / lengthSegments);

  // sample the curve in two places
  vec3 current = mysample(t);
  vec3 next = mysample(nextT);
  
  float distortion =pnoise(vec3(current), vec3(150.)) * 1.;

  //current = current + distortion; 

  // compute the TBN matrix
  vec3 T = normalize(next - current);
  vec3 B = normalize(cross(T, next + current));
  vec3 N = -normalize(cross(B, T));

  // extrude outward to create a tube
  float tubeAngle = angle;
  float circX = cos(tubeAngle);
  float circY = sin(tubeAngle);

  // compute position and normal
  normal.xyz = normalize(B * circX + N * circY) ;
  offset.xyz = current + B * volume.x * circX + N * volume.y * circY ;
}

void main() 
{ 
  float t = (xposition * 2.0) * 0.5 + 0.5;
  vUv = uv;

  increment = t;


    // build our tube geometry
  vec2 volume = vec2(thickness);

  // animate the per-vertex curve thickness
  float volumeAngle = t * lengthSegments * 0.5 + index * 20.0 ;
  float volumeMod = sin(volumeAngle) * 0.5 + 0.5;
 
 if(type == 0.0 || type == 1.0){
  float vol2 = mix(0., volume.x , xposition+ 0.5);  
  float vol = mix( vol2 , 0.,xposition+ 0.5);  
  volume.y = vol*1.;
  volume.x = vol;
 }

  // build our geometry
  vec3 transformed;
  vec3 objectNormal;
  createTube(t, volume, transformed, objectNormal);
   

 // pass the normal and UV along
  vec3 transformedNormal = normalMatrix * objectNormal;
  vNormal = normalize(transformedNormal);
  vUv = uv.yx; // swizzle this to match expectations

  vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
  vec3 I = worldPosition.xyz - cameraPosition;

  vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
  vViewPosition = -mvPosition.xyz;

  vec4 p = vec4( transformed, 1. );

  vec3 e = normalize( vec3( modelViewMatrix * p ) );
  vec3 n = normalize( normalMatrix * normal );

  vec3 r = reflect( e, n );
  float m = 2. * sqrt(
    pow( r.x, 2. ) +
    pow( r.y, 2. ) +
    pow( r.z + 1., 2. )
  );
  vN = r.xy / m + .5;

   vec4 localPosition = vec4( transformed, 1.);
   worldPosition = modelMatrix * localPosition;
   uPosition = worldPosition.rgb;


  gl_Position = projectionMatrix * mvPosition;
       
}
        `,
        fragmentShader: 
        `

varying vec3 uPosition;
uniform vec3 lightPosition;
uniform sampler2D tMatCap;
varying vec2 vN;
varying vec2 vUv;
varying float increment;
varying vec3 vNormal;

vec3 cosPalette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
  return a + b * cos(6.28318 * (c * t + d));
}  


float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main() 
{
  vec3 base2 = texture2D( tMatCap, vN ).rgb;

  //distanza da zero
  float dist = length(vec3(0.,0.,0.) - uPosition);
  float dist_light = length( lightPosition - uPosition);

  gl_FragColor =  vec4(base2*1.5 - dist/25., 1.0);
      
  if(dist_light < 15.)
  gl_FragColor =  vec4(base2*1.5 - dist/45.  + (15./50. - dist_light/50.) , 1.0);


  vec3 brightness = vec3(0.5, 0.5, 0.5);
  vec3 contrast = vec3(0.5, 0.5, 0.5);
  vec3 oscilation = vec3(1.0, 1.0, 1.0);
  vec3 phase = vec3(0.0, 0.1, 0.2);

  // Pass the distortion as input of cospalette


  vec3 normal = vNormal;
    float diffuse = normal.y * 0.055 + 0.055;
   vec3 text = texture2D( tMatCap, vUv ).rgb  - diffuse;
  

  vec3 color = cosPalette(text.r, brightness, contrast, oscilation, phase);
  gl_FragColor = vec4(vec3(text), 1.0);

        vec4 base_color =  vec4(text.rgb/2.5, 1.0);
        vec4 final_color = base_color;





        float min_dist = 30.;

        if(dist_light < min_dist){
        final_color.r = map(dist_light, 10., min_dist, text.r, base_color.r);
        final_color.g = map(dist_light, 10., min_dist, text.g, base_color.g);
        final_color.b = map(dist_light, 10., min_dist, text.b, base_color.b);
     
          
      }
        
gl_FragColor = final_color;
}

        `
    }   );


    myCylinderGeometry.setAttribute('xposition', new THREE.BufferAttribute(xpositions, 1));
    myCylinderGeometry.setAttribute('angle', new THREE.BufferAttribute(values, 1));
    myCylinderGeometry.setAttribute('uv', new THREE.BufferAttribute(myCylinderGeometry.attributes.uv.array, 2));
    myCylinderGeometry.setAttribute('indices', new THREE.BufferAttribute(indices, 1));

    var mycylinder = new THREE.Mesh( myCylinderGeometry, customMaterial );
    this.myshapes.add( mycylinder )
    this.myuniforms.push(mycustomUniforms);
}
    }
}
