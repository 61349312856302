import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import noise_texture from '../assets/images/shape.jpg';


export default class Ribbon {
    constructor(elements_positions, elements_index, elements_size) {

        const loader = new GLTFLoader();
        this.ribbon = new THREE.Group();
        this.noise_texture = new THREE.TextureLoader().load(noise_texture);

        this.noise_texture.repeat.x = this.noise_texture.repeat.y = THREE.RepeatWrapping;
        this.noise_texture.wrapS = this.noise_texture.wrapT  = THREE.MirroredRepeatWrapping;

        this.blob_material = new THREE.ShaderMaterial({

            uniforms:
            {
                time: { type: 'f', value: 0 },
                text: { type: 't', value: this.noise_texture }, 
                lightPosition :{  value: new THREE.Vector3(0,0,0) }, 
                elements_positions : { value: elements_positions }, 
                elements_index : { value: elements_index }, 
                elements_size : { value: elements_size }, 
            },
            vertexShader: 
            `
            varying vec2 vUv;
            varying vec3 pos;
            varying vec2 vN;
            varying vec3 norm;

            uniform float time;

            vec3 mod289(vec3 x) {
              return x - floor(x * (1.0 / 289.0)) * 289.0;
          }

          vec4 mod289(vec4 x) {
              return x - floor(x * (1.0 / 289.0)) * 289.0;
          }

          vec4 permute(vec4 x) {
           return mod289(((x*34.0)+1.0)*x);
       }

       vec4 taylorInvSqrt(vec4 r)
       {
          return 1.79284291400159 - 0.85373472095314 * r;
      }

      vec3 fade(vec3 t) {
          return t*t*t*(t*(t*6.0-15.0)+10.0);
      }

      float pnoise(vec3 P, vec3 rep)
      {
          vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
          vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
          Pi0 = mod289(Pi0);
          Pi1 = mod289(Pi1);
          vec3 Pf0 = fract(P); // Fractional part for interpolation
          vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
          vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
          vec4 iy = vec4(Pi0.yy, Pi1.yy);
          vec4 iz0 = Pi0.zzzz;
          vec4 iz1 = Pi1.zzzz;

          vec4 ixy = permute(permute(ix) + iy);
          vec4 ixy0 = permute(ixy + iz0);
          vec4 ixy1 = permute(ixy + iz1);

          vec4 gx0 = ixy0 * (1.0 / 7.0);
          vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
          gx0 = fract(gx0);
          vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
          vec4 sz0 = step(gz0, vec4(0.0));
          gx0 -= sz0 * (step(0.0, gx0) - 0.5);
          gy0 -= sz0 * (step(0.0, gy0) - 0.5);

          vec4 gx1 = ixy1 * (1.0 / 7.0);
          vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
          gx1 = fract(gx1);
          vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
          vec4 sz1 = step(gz1, vec4(0.0));
          gx1 -= sz1 * (step(0.0, gx1) - 0.5);
          gy1 -= sz1 * (step(0.0, gy1) - 0.5);

          vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
          vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
          vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
          vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
          vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
          vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
          vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
          vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

          vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
          g000 *= norm0.x;
          g010 *= norm0.y;
          g100 *= norm0.z;
          g110 *= norm0.w;
          vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
          g001 *= norm1.x;
          g011 *= norm1.y;
          g101 *= norm1.z;
          g111 *= norm1.w;

          float n000 = dot(g000, Pf0);
          float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
          float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
          float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
          float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
          float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
          float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
          float n111 = dot(g111, Pf1);

          vec3 fade_xyz = fade(Pf0);
          vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
          vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
          float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
          return 2.2 * n_xyz;
      }

      void main() {
          vUv = uv;
          vec3 newPosition = position;
          pos = position;
          float displacement =  35. * (pnoise( pos/350. + time, vec3( 10000000.0 ) ));

          float yvalue = (normal.y * displacement);
          float xvalue = (normal.x * displacement );
          float zvalue = (normal.z * displacement );

          newPosition.x += xvalue ;
          newPosition.y += yvalue;
          newPosition.z += zvalue;

          norm = normal;
          vec4 localPosition = vec4( position, 1.);
          vec4 worldPosition = modelMatrix * localPosition;

          //interessante ma no
          //pos = vec4(modelViewMatrix * vec4( position + vec3(displacement),1.0)).rgb;
          pos = vec4(modelViewMatrix * vec4( position + vec3(displacement),1.0)).rgb;
          pos = worldPosition.rgb;

          gl_Position = projectionMatrix * modelViewMatrix * vec4( position + vec3(displacement),1.0);
      }
      `
      ,
      fragmentShader: 
      `

      uniform sampler2D text;
      varying vec3 pos;
      varying vec2 vN;
      varying vec3 norm;
      varying vec2 vUv;
      uniform vec3 lightPosition;
      uniform vec3 elements_positions[ 30 ];
      uniform float elements_index[ 30 ];
      uniform float elements_size[ 30 ];
      uniform float time;

      float map(float value, float min1, float max1, float min2, float max2) {
          return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
      }


      float unpackDepth( const in vec4 rgba_depth ) {

          const vec4 bit_shift = vec4( 1.0 / ( 256.0 * 256.0 * 256.0 ), 1.0 / ( 256.0 * 256.0 ), 1.0 / 256.0, 1.0 );
          float depth = dot( rgba_depth, bit_shift );
          return depth;

      }

      void main()
      {

        vec2 myvUv = vUv;  
        myvUv.y =+ time/12.;
        vec4 color_texture = texture2D(text, myvUv); 

        //float depth = 2.0 - unpackDepth( texture2D( text, myvUv ) );
        //color_texture = vec4( vec3( depth ), 1.0 );

        //distance from center
        float dist = length( vec3(0.,0.,0.) - pos.rgb);

        //distance from pointer
        float dist_light = length( lightPosition - pos.rgb);

        float diffuse = norm.y * 0.1 + 0.1;

        vec4 base_color =  vec4(color_texture.rgb/3.5- dist/150., 1.0);
        
        vec4 final_color = base_color ;
        float min_dist = 15.;

        if(dist_light < min_dist){
            final_color.r = map(dist_light, 5., min_dist, color_texture.r, base_color.r);
            final_color.g = map(dist_light, 5., min_dist, color_texture.g, base_color.g);
            final_color.b = map(dist_light, 5., min_dist, color_texture.b, base_color.b);
        }

        vec4 element_color = vec4(0.);

        for(int i=0;i<30;++i)
        {
         float dist_element = length( elements_positions[i] - pos.rgb);
         float min_dist_element = elements_size[ i ] * 2.;

         if(dist_element < min_dist_element) {
            vec4 selected_color = vec4(0.);

            if(elements_index[i] == 0.) selected_color =  vec4(248./255., 166./255., 48./255., 1.);
            if(elements_index[i] == 1.) selected_color =  vec4(234./255., 53./255., 98./255., 1.);
            if(elements_index[i] == 2.) selected_color =  vec4(109./255., 215./255., 216./255., 1.);

            element_color.r += map(dist_element, 0., min_dist_element, selected_color.r, 0.); 
            element_color.g += map(dist_element, 0., min_dist_element, selected_color.g, 0.); 
            element_color.b += map(dist_element, 0., min_dist_element, selected_color.b, 0.); 

        }
    }

    final_color += element_color/1.5;// + map(dist, 0., 100., 0.065, 0.);
    final_color = clamp(final_color, 0. , 1.);
    gl_FragColor = final_color ;
}

`,
});


loader.load("./models/bigblob.gltf", (gltf) => {
    let obj = gltf.scene;

    for(let i = 0; i<2; i++){
        let obj_tmp = obj.clone();
        obj_tmp.position.set(2,8 - i*23,.2);
        obj_tmp.rotation.x= 10.20;
        obj_tmp.rotation.y= -3.14;
        obj_tmp.rotation.z= 2.35;

        if(i === 1){
            obj_tmp.rotation.x= 10.46;
            obj_tmp.rotation.y= -3.13;
            obj_tmp.rotation.z= 4.96;
        }

        let scale_factor = 0.027;
        obj_tmp.scale.set(scale_factor,scale_factor,scale_factor);

        this.ribbon.add( obj_tmp ); 

        obj_tmp.traverse((o) => {
            if (o.isMesh) {
                o.material = this.blob_material;
            }
        });

    }
},

);


}

}
