import React, { Component } from "react";
import Ribbon from "./Ribbon.js";
import Lines from "./Lines.js";
import * as THREE from "three";
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import glow from '../assets/images/glow.png';
import portal_texture from '../assets/images/Superunion_ligature_white_RGB.png';
import text_texture from '../assets/images/text.png';
import helper_texture from '../assets/images/helper.png';

class PortalComponent extends Component {

    componentDidMount() {
        this.cameraTagert = new THREE.Vector3();
        this.clock = new THREE.Clock();
        this.mouse = new THREE.Vector2();
        this.camera_distance = 50;
        this.hit_group = new THREE.Group();
        this.mouse_over_portal = false;

        this.setScene();
        this.setControls();
        this.CreatePortal();
        this.CreateParticles();
        this.CreateSpark();
        // this.CreateText();
        this.lines = new Lines();
        this.scene.add(this.lines.myshapes);

        this.ribbon_object = new Ribbon(this.elements_positions, this.elements_index, this.elements_size);
        this.ribbon = this.ribbon_object.ribbon;
        this.scene.add(this.ribbon);

        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener('mousemove', this.onMouseMove, false);
        window.addEventListener("keydown", this.onDocumentKeyDown, false);
        // window.addEventListener("touchend", this.onDocumentMouseDown, false);
        // window.addEventListener( 'mousedown', this.onDocumentMouseDown, false);

        this.animate();
    }

    setScene = () => {
        // get container dimensions and use them for scene sizing
        const SCREEN_WIDTH = window.innerWidth;
        const SCREEN_HEIGHT = window.innerHeight;
        this.camera  = new THREE.PerspectiveCamera( 65, window.innerWidth / window.innerHeight, 1, 100 );
        this.camera.position.set( 0, 0, 55 );
        this.scene = new THREE.Scene();

        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
        //this.renderer.setClearColor(0x110135, 1);// (0xE4EAF1, 1);
        this.container.appendChild(this.renderer.domElement);

        this.canvas = this.renderer.domElement;
        this.raycaster = new THREE.Raycaster();

        this.group = new THREE.Group();
        this.scene.add(this.group);
        this.angle = 0;
        this.autoRotateMode = true;
    };

    CreatePortal(){
        this.portal_texture = new THREE.TextureLoader().load(portal_texture);
        const portal_material = new THREE.MeshBasicMaterial({
            map: this.portal_texture,
            transparent: true,
            depthWrite:false
        });

    const portal_geometry = new THREE.PlaneGeometry( 1.5*4.75, 1*4.75 );
    this.main_portal = new THREE.Mesh(portal_geometry, portal_material)
    this.main_portal.name = 'portal';
    this.hit_group.add( this.main_portal );

    this.helper_material = new THREE.ShaderMaterial({

    uniforms:
    {
      text: { type: 't', value: new THREE.TextureLoader().load( helper_texture ) },
      lightPosition :{  value: new THREE.Vector3(0,0,0) },
    },

  vertexShader: `
  varying vec2 vUv;
varying vec3 pos;
uniform vec3 lightPosition;

void main() {
    vUv = uv;
    pos = position;

    float dist = (length(lightPosition - pos));
     vec3 position2 = position; //+ vec3(5.,-4.,3.);

        vec4 localPosition = vec4( position, 1.);
      vec4 worldPosition = modelMatrix * localPosition;
     pos = worldPosition.rgb;

    if (dist < 3.){
     // position2 = vec3(position.x + (3. - dist), position.y, position.z);
    }
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position2,1.0);
}
`,
  fragmentShader: `
    uniform sampler2D text;
    uniform vec3 lightPosition;

    varying vec2 vUv;
    varying vec3 pos;
    void main()
    {
        float dist = length(lightPosition - pos);
        vec4 color_texture = texture2D(text, vUv);

         if (dist < 8.){
        vec4 color_texture2 =  texture2D(text, vUv);
        color_texture = color_texture2;
        }else{
        color_texture.a = 0.;
      }
        gl_FragColor = color_texture;
    }`,
  transparent: true
});
    const helper_geometry = new THREE.PlaneGeometry( 1.7*3, 1*3 );
    this.helper = new THREE.Mesh(helper_geometry, this.helper_material)
    this.helper.position.y = -4;
    this.helper.position.Z = 1;
    this.main_portal.add(this.helper);



}

    CreateText(){
    this.text_texture = new THREE.TextureLoader().load(text_texture);
    const text_material = new THREE.MeshBasicMaterial({
        map: this.text_texture,
        transparent: true,
        depthWrite:false,
        side: THREE.DoubleSide
    });

    const text_geometry = new THREE.PlaneGeometry( 1.7*15, 1*15 );
    this.text = new THREE.Mesh(text_geometry, text_material)
    this.text.position.set(0,-10,15);
    this.scene.add( this.text );
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


 CreateParticles(){
    this.elements_positions = [];
    this.elements_index = [];
    this.elements_size = [];

    const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 16 )
    const particle_material = new THREE.MeshBasicMaterial({
        color: 0xffffff
    });
    const elements = new THREE.InstancedMesh(sphereGeometry, particle_material, 30);
    const dummy_element = new THREE.Object3D();
    const position = new THREE.Vector3();
    const color = new THREE.Color();
    const color_palette = [ 0xf8a630, 0xea3565, 0x6dd6d8 ];
    var glow_texture = new THREE.TextureLoader().load(glow);

    var spriteMaterial1 = new THREE.SpriteMaterial(
    {
        map: glow_texture,
        color: color_palette[0], blending: THREE.AdditiveBlending,
        depthWrite:false
    });

        var spriteMaterial2 = new THREE.SpriteMaterial(
    {
        map: glow_texture,
        color: color_palette[1], blending: THREE.AdditiveBlending,
        depthWrite:false
    });

        var spriteMaterial3 = new THREE.SpriteMaterial(
    {
        map: glow_texture,
        color: color_palette[2], blending: THREE.AdditiveBlending,
        depthWrite:false
    });

    const spriteMaterials = [];
    spriteMaterials.push(spriteMaterial1);
    spriteMaterials.push(spriteMaterial2);
    spriteMaterials.push(spriteMaterial3);

    for (let i = 0; i < elements.count; i++) {
        let factor=30;
        position.set(
            Math.random()*factor - factor/2,
            Math.random()*factor - factor/2,
            Math.random()*factor - factor/2
            )

        this.elements_positions.push(new THREE.Vector3(position.x, position.y, position.z));

        let factor_scale = 2;
        let scaling = 1.25 +  Math.random()*factor_scale-factor_scale/2;
        dummy_element.scale.set(scaling,scaling,scaling)
        dummy_element.position.copy(position)
        dummy_element.updateMatrix();
        dummy_element.rotation.set(Math.random()*Math.PI,Math.random()*Math.PI*2,Math.random()*Math.PI*2)
        elements.setMatrixAt(i, dummy_element.matrix);
        const color_index = Math.floor( Math.random() * color_palette.length );
        color.setHex( color_palette[ color_index ] );
        elements.setColorAt( i, color );
        this.elements_index.push(color_index);
        this.elements_size.push(scaling);

        var sprite = new THREE.Sprite( spriteMaterials[color_index] );
        sprite.scale.set(scaling/2, scaling/2, scaling/2);
        sprite.position.copy(position)
        this.scene.add(sprite);
    }

    this.scene.add(elements);

    window.scene = this.scene;



}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


CreateSpark(){

    const geometry = new THREE.PlaneGeometry( 250, 250 );
    const material = new THREE.MeshBasicMaterial( {color: 0x00ffff, side: THREE.DoubleSide} );
    this.hit_plane = new THREE.Mesh( geometry, material );
    this.hit_plane.name = 'collison_plane';
    this.hit_plane.visible = false;
    var glow_texture = new THREE.TextureLoader().load(glow);

    const spark_geometry = new THREE.SphereGeometry( 0.25, 16, 8 );
    const spark_material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
    this.spark = new THREE.Mesh( spark_geometry, spark_material );
    this.scene.add( this.spark );
    this.hit_group.add( this.hit_plane );
    this.scene.add(this.hit_group);

    var spriteMaterial = new THREE.SpriteMaterial(
    {
        map: glow_texture,
        color: 0xffffff, blending: THREE.AdditiveBlending,
        depthWrite:false
    });
    var sprite = new THREE.Sprite( spriteMaterial );
    sprite.scale.set(2.25, 2.25, 2.25);
    this.spark.add(sprite);
}



    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.removeEventListener("mousemove", this.onMouseMove);
        window.cancelAnimationFrame(this.requestID);
        this.controls.dispose();
    }



    setControls = () => {
        this.controls = new TrackballControls(this.camera, this.renderer.domElement);
        this.controls.maxDistance =55;
        this.controls.minDistance = 5;
        this.controls.rotateSpeed = 5;
        this.controls.zoomSpeed = 0.25;
        this.controls.noPan = true;
        this.controls.noZoom = false;
    }



    animate = () => {

        if (!this.props.interacted) {
            // this.scene.children.map( c => {
                // c.rotation.y += 0.005;
            // });
            this.ribbon.rotation.y += 0.005;
            // this.ribbon.rotation.z += 0.005;
        }
        this.controls.update();

        this.renderer.render(this.scene, this.camera);

        this.updateSpark();
        this.requestID = window.requestAnimationFrame(this.animate);

        this.main_portal.quaternion.copy(this.camera.quaternion);
        this.hit_plane.quaternion.copy(this.camera.quaternion);
        this.setRaycast();

        this.ribbon.rotation.y += 0.0005;
        this.ribbon.rotation.z += 0.0001;

        var t = this.clock.getElapsedTime();

        this.updateShape(t);
        this.updateScribble(t);

    };

    updateSpark(){
        this.spark.position.x += (this.cameraTagert.x - this.spark.position.x)*0.05;
        this.spark.position.y += (this.cameraTagert.y - this.spark.position.y)*0.05;
        this.spark.position.z += (this.cameraTagert.z - this.spark.position.z)*0.05;
    }

    updateScribble(t){
        this.helper_material.uniforms.lightPosition.value = this.spark.position;
        this.ribbon_object.blob_material.uniforms.lightPosition.value = this.spark.position;
        this.ribbon_object.blob_material.uniforms.time.value = t/5;
        this.ribbon_object.blob_material.uniforms.time.value = t/5;
    }

     updateShape(t){
        for(let i = 0; i< this.lines.myuniforms.length; i++){
            this.lines.myuniforms[i].mixAmount.value = t*1.4;
            this.lines.myuniforms[i].lightPosition.value = this.spark.position;
        }
    }


    handleWindowResize = () => {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    };

    onMouseMove = (event) => {
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }

    setRaycast = () => {

        this.raycaster.setFromCamera(this.mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.hit_group.children);

        document.body.style.cursor = 'default';
        this.mouse_over_portal = false;
        for ( let i = 0; i < intersects.length; i ++ ) {
             this.cameraTagert.set(intersects[ 0 ].point.x,intersects[ 0 ].point.y,intersects[ 0 ].point.z);
                if(intersects[i].object.name === "portal"){
                    document.body.style.cursor = 'pointer';
                    this.mouse_over_portal = true;
                    if (this.props.hovering === false) {
                        this.props.update({ hovering: true });
                    }
                }
        }
        if (this.mouse_over_portal === false && this.props.hovering === true) {
            this.props.update({ hovering: false });
        }
    }

    onDocumentMouseDown= () => {
        if (this.mouse_over_portal === true){
            console.log('clicked');
            this.props.update({ clicked: Math.random() });
        }
        if (!this.props.interacted) {
            this.props.update({ interacted: true });
        }
    }

   render() {
        return (
            <>
                <div className="canvas_container"
                    ref={ref => (this.container = ref)}
                    onClick={e => this.onDocumentMouseDown()} />
               <a className={`touch-enter-button ${this.props.touch_enabled ? 'showing' : ''}`} href={this.props.portal_url}>&nbsp;</a>
           </>);
    }
}

export default PortalComponent;
